const timeThresholds = [
    { limit: 60, label: 'timeIn_1' },
    { limit: 2 * 60, label: 'timeIn_2' },
    { limit: 3 * 60, label: 'timeIn_3' },
    { limit: 5 * 60, label: 'timeIn_5' },
    { limit: 10 * 60, label: 'timeIn_10' },
    { limit: 15 * 60, label: 'timeIn_15' },
];
const scrollDepths = [
    { value: 25, hasTriggered: false },
    { value: 50, hasTriggered: false },
    { value: 75, hasTriggered: false },
    { value: 90, hasTriggered: false },
];
export const trackTimeOnPage = (startPerformanceTime: number) => {
    const elapsedMillis = performance.now() - startPerformanceTime;
    const elapsedSeconds = Math.round(elapsedMillis / 1000);

    let label = 'timeOut_15';
    for (let threshold of timeThresholds) {
        if (elapsedSeconds < threshold.limit) {
            label = threshold.label;
            break;
        }
    }

    event({
        action: 'time',
        category: 'Engagement',
        label: label,
        value: `${elapsedSeconds} seconds`,
    });
};

export const trackScrollDepth = () => {
    window.addEventListener('scroll', handleScroll);
};

export const resetScroll = () => {
    scrollDepths.forEach(depth => (depth.hasTriggered = false));
    // Reattach the event listener after resetting the scroll depths on a new page
    window.addEventListener('scroll', handleScroll);
};

const handleScroll = () => {
    const scrollHeight = document.body.scrollHeight;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollPercent = ((scrollTop + windowHeight) / scrollHeight) * 100;
    const roundedPercent = Math.round(scrollPercent);

    scrollDepths.forEach(depth => {
        if (roundedPercent >= depth.value && !depth.hasTriggered) {
            event({
                action: 'scroll',
                category: 'Engagement',
                label: `scroll_${depth.value}`,
                value: depth.value,
            });
            depth.hasTriggered = true;

            if (depth.value === 90) {
                window.removeEventListener('scroll', handleScroll);
            }
        }
    });
};

export const event = ({ action, category, label, value }: any) => {
    if (!window.gtag) return;
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};
