import { GOOGLE_ID } from '@/shared/config/processKeys';
import { resetScroll, trackScrollDepth, trackTimeOnPage } from '@/shared/helpers/gtagHelper';
import { getLocal } from '@/shared/helpers/storageHelper';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

export default function GoogleAnalytics({ GA_MEASUREMENT_ID = GOOGLE_ID }: { GA_MEASUREMENT_ID?: string }) {
    const storedCookieConsent = getLocal('cookie_consent', null);
    const router = useRouter();
    const defaultConsentState = {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied',
    };
    const storedString = JSON.stringify(storedCookieConsent ? storedCookieConsent : defaultConsentState);
    const consent = `gtag('consent', 'update', ${storedString});`;
    useEffect(() => {
        const startTime = performance.now();
        if (storedCookieConsent) {
            gtag('event', 'ConsentConfiguredEvent');
            trackScrollDepth();

            const handleRouteChange = () => {
                resetScroll();
                trackTimeOnPage(startTime);
            };
            router.events.on('routeChangeComplete', handleRouteChange);
            return () => {
                router.events.off('routeChangeComplete', handleRouteChange);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <noscript>
                <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${GA_MEASUREMENT_ID}`}
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                />
            </noscript>
            <Script
                id="gtm-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${GA_MEASUREMENT_ID}');`,
                }}
            />
            <Script id="google-consent" strategy="afterInteractive">
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('consent', 'default', ${JSON.stringify(defaultConsentState)});
                ${consent}
                `}
            </Script>
            <Script id="google-analytics" strategy="afterInteractive">
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                `}
            </Script>
        </>
    );
}
