import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
export type SlugType = {
    path: string;
    name: string;
    lang: string;
};
type MetatagsType = {
    slugsArray: SlugType[];
    default: string;
    metaTags: {
        title: string;
        description: string;
        og_title: string;
        og_description: string;
        og_image: string;
        twitter_description: string;
        twitter_image: string;
        twitter_title: string;
    };
};
type ImageType = {
    url: string;
    width: number;
    height: number;
    alt: string;
};

export default function Metatags(props: MetatagsType) {
    const router = useRouter();

    const slugsArray = Array.isArray(props?.slugsArray) ? props?.slugsArray : [];

    const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
    const {
        title,
        description,
        og_title,
        og_description,
        og_image,
        twitter_image,
        twitter_description,
        twitter_title,
    } = props?.metaTags;
    const hreflangs = [
        ...slugsArray,
        {
            path: props?.default || '/',
            name: 'English',
            lang: 'en',
        },
    ];
    const languageAlternates = hreflangs.map(hreflang => ({
        href: `${baseUrl}/${hreflang.lang}${hreflang.path === 'home' ? '' : '/' + hreflang.path}`,
        hrefLang: hreflang.lang,
    }));
    const [openGraphImages, setOpenGraphImages] = useState<ImageType[]>([
        {
            url: twitter_image,
            width: 800,
            height: 600,
            alt: 'Twitter Image Alt',
        },
    ]);
    useEffect(() => {
        const imagesArray = [];
        if (og_image) {
            imagesArray.push({
                url: og_image,
                width: 800,
                height: 600,
                alt: 'Og Image Alt',
            });
        }
        if (twitter_image) {
            imagesArray.push({
                url: twitter_image,
                width: 800,
                height: 600,
                alt: 'Twitter Image Alt',
            });
        }
        setOpenGraphImages(imagesArray);
    }, [og_image, twitter_image]);

    return (
        <>
            <Head>
                <link rel="icon" href="/favicon.ico" />
                <meta name="google-site-verification" content="KzU1UfxB0uvME4FOGuLbaLoawZp_Spn3WhX-vcsYy_w" />
            </Head>
            <NextSeo
                title={title}
                description={description}
                languageAlternates={languageAlternates}
                openGraph={{
                    url: `${baseUrl}/${router.locale}${router.asPath === 'home' ? '' : router.asPath}`,
                    title: og_title || twitter_title,
                    description: og_description || twitter_description,
                    locale: router.locale,
                    images: openGraphImages,
                    siteName: 'Mindnow',
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
        </>
    );
}
