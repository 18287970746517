import { SlugType } from '@/components/Metatags/Metatags';
import * as Sentry from '@sentry/nextjs';
import React, { createContext, useContext, useState } from 'react';
import useStorage from '../helpers/sessionStorage';

interface DataProviderProps {
    children?: React.ReactNode;
    pageName: string;
    pageType: string;
    pageMainColor?: string;
    version?: 'published' | 'draft';
    slugs: any;
}

interface DataContextValue {
    data: Record<string, any>;
    version?: 'published' | 'draft';
    setDataForId: (id: string, content: any) => void;
    pageMainColor?: string;
    pageName: string;
    pageType: string;
    slugs: {
        default: string;
        translatedSlugs: SlugType[];
        forbiddenPages: string[];
    };
}

export const DataContext = createContext<DataContextValue>({
    data: {},
    setDataForId: () => {},
    pageName: '',
    pageType: '',
    slugs: {
        default: '',
        translatedSlugs: [],
        forbiddenPages: [],
    },
});

export const DataProvider: React.FC<DataProviderProps> = ({
    children,
    pageName,
    pageType,
    slugs,
    pageMainColor,
    version = 'draft',
}) => {
    const { getItem, setItem } = useStorage();
    const initialData = JSON.parse(getItem('data') || '{}');
    const [data, setData] = useState<Record<string, any[]>>(initialData);
    const setDataForId = (id: string, content: any[]) => {
        setData(prev => {
            const updatedData = {
                ...prev,
                [id]: content,
            };
            setItem('data', JSON.stringify(updatedData));
            return updatedData;
        });
    };

    return (
        <DataContext.Provider value={{ data, setDataForId, pageName, pageType, slugs, pageMainColor, version }}>
            {children}
        </DataContext.Provider>
    );
};
export const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
        Sentry.captureException('useData must be used within a DataProvider', {
            tags: {
                component: 'Dataprovider',
            },
            level: 'error',
        });
        if (window?.location) {
            window?.location?.reload();
        }
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};
