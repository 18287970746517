import { FooterStoryblok } from '@/shared/types/storyblok/storyblok-types';

export type Microcopy = {
    articleBy: string;
    loadMoreButton: string;
    featuredArticleButton: string;
    filterDefaultOption: string;
    articleMindsTitle: string;
    imageSourceText: string;
    videoSourceText: string;
    involvedAs: string;
    authorTitle: string;
    menu: string;
    close: string;
    back: string;
    footer: FooterStoryblok;
    achievements: string;
    readingTimeText: string;
    chapters: string;
    openCardButton: string;
};
export type MicrocopyState = {
    microcopy: {
        articleBy: string;
        loadMoreButton: string;
        featuredArticleButton: string;
        filterDefaultOption: string;
        articleMindsTitle: string;
        imageSourceText: string;
        videoSourceText: string;
        involvedAs: string;
        authorTitle: string;
        menu: string;
        close: string;
        back: string;
        footer: FooterStoryblok;
        achievements: string;
        readingTimeText: string;
        chapters: string;
        openCardButton: string;
    };
};
export type UpdateMicrocopyAction = {
    type: 'update-microcopy';
    payload: {
        microcopy: Microcopy;
    };
};

/**
 * @param state the current color
 * @param action the action and value to be used to update the color state
 * @returns the new color state
 */
const microcopyReducer = (state: { microcopy: Microcopy }, action: UpdateMicrocopyAction) => {
    if (action.type === 'update-microcopy') {
        const { microcopy } = action.payload;
        return { ...state, microcopy };
    } else {
        return state;
    }
};
export default microcopyReducer;
