import '@/assets/scss/index.scss';
import GoogleAnalytics from '@/components/GoogleAnalytics/GoogleAnalytics';
import Metatags from '@/components/Metatags/Metatags';
import { GOOGLE_ID } from '@/shared/config/processKeys';
import { DataProvider } from '@/shared/providers/DataProvider';
import { MicrocopyProvider } from '@/shared/providers/Microcopy/MicrocopyContext';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import { components } from '../shared/config/components';
const production = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

storyblokInit({
    accessToken: process.env.storyblok,
    bridge: true,
    apiOptions: {
        cache: { type: production ? 'memory' : 'none', clear: 'auto' },
        maxRetries: 10,
    },
    use: [apiPlugin],
    components,
});
export default function App({ Component, pageProps }: AppProps) {
    const { content, default_full_slug, translated_slugs, lang, name } = pageProps?.story || {};
    const { pageMainColor, seoMetatags, component } = content || {};
    const slugs = {
        default: default_full_slug,
        translatedSlugs: translated_slugs,
        forbiddenPages: content?.disabledTranslations,
    };
    const pageName = lang === 'de' ? slugs.translatedSlugs[0].name ?? 'Prozess' : name;

    return (
        <>
            {production && GOOGLE_ID && <GoogleAnalytics GA_MEASUREMENT_ID={GOOGLE_ID} />}
            <Metatags slugsArray={translated_slugs} default={default_full_slug} metaTags={seoMetatags || []} />
            <AnimatePresence mode="wait">
                <div key={default_full_slug}>
                    <MicrocopyProvider>
                        <DataProvider
                            slugs={slugs}
                            pageName={pageName}
                            pageType={component}
                            pageMainColor={pageMainColor}
                            version={production ? 'published' : 'draft'}
                        >
                            <Component {...pageProps} />
                        </DataProvider>
                    </MicrocopyProvider>
                </div>
            </AnimatePresence>
        </>
    );
}
