import 'client-only';
import { CookieHelper } from './cookie';
import * as Sentry from '@sentry/nextjs';

export function getLocal(key: string, defaultValue: any) {
    if (typeof window === 'undefined') return defaultValue;

    let stickyValue = CookieHelper.get(key);

    if (stickyValue) {
        try {
            return JSON.parse(decodeURIComponent(stickyValue));
        } catch (error) {
            Sentry.captureException('Failed to parse the decoded cookie value: ' + stickyValue, {
                tags: {
                    component: 'storage helper',
                },
                level: 'warning',
            });
        }
    }

    return defaultValue;
}

export function setLocal(key: string, value: any) {
    if (localStorage) {
        const encodedValue = encodeURIComponent(JSON.stringify(value));
        CookieHelper.set(key, encodedValue);
    }
}
