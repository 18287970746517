const get = (name: string) => {
    if (typeof window === 'undefined') return;
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
};

const remove = (name: string, domain?: string) => {
    if (typeof window === 'undefined') return;
    document.cookie = `${name}=; Path=/; ${domain ? `domain=${domain};` : ''} Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

const set = (name: string, value: string, date?: Date, domain?: string) => {
    if (typeof window === 'undefined') return;

    const expiredDate = date || new Date();

    if (!date) expiredDate.setDate(expiredDate.getDate() + 30); // 30 days expiry for now // TODO use refresh token later

    document.cookie = `${name}=${value};  ${
        domain ? `domain=${domain};` : ''
    } path=/; Expires=${expiredDate.toUTCString()};`;
};

export const CookieHelper = {
    get,
    set,
    remove,
};
